import React from "react";
import { Modal } from "@mui/material";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiArrowBack } from "react-icons/bi";
import Signin from "../Auth/Sign-in/Signin";
import { setNewPassword } from "../../services/authService";
import { TfiClose } from "react-icons/tfi";
import { useDispatch, useSelector } from "react-redux";
import { modalManage } from "../../redux/slices/authModalHandleSlice";
import { Button } from "@mui/material";

const ResetPassword = ({ phoneNumber }) => {
  const dispatch = useDispatch();
  const modalHandle = useSelector((state) =>
    _.get(state, "reducer.authModalHandle.modalHandle", {})
  );
  const handleClose = () => {
    dispatch(modalManage("allModalClose"));
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const ResetPassSchema = Yup.object().shape({
    otp: Yup.string()
      .required("OTP can not be empty")
      .matches(phoneRegExp, "OTP is not valid")
      .max(6, "OTP is too long"),
    password: Yup.string()
      .required("Password cannot be empty")
      .min(8, "Password must be 8 characters or more")
      .matches(/[a-z]+/, "One lowercase character")
      .matches(/[A-Z]+/, "One uppercase character")
      .matches(/[@$!%*#?&]+/, "One special character")
      .matches(/\d+/, "One number"),
    confirmpass: Yup.string()
      .required("Confirm password cannot be empty")
      .oneOf([Yup.ref("password")], "Your passwords do not match."),
  });

  const onSubmit = async (values, setSubmitting, resetForm) => {
    let reqData;
    reqData = {
      email: phoneNumber,
      otp: _.toNumber(_.get(values, "otp", "")),
      new_password: _.get(values, "password", ""),
    };
    const response = await setNewPassword(reqData).catch((err) =>
      toast.error(_.get(err, "message.error", "Invalid OTP...!"))
    );
    if (_.get(response, "status", "") === 1) {
      toast.success(_.get(response, "message", ""));
      dispatch(modalManage("login"));
      resetForm();
    } else {
    }
  };

  const handleBack = () => {
    dispatch(modalManage("login"));
  };

  return (
    <>
      <Modal
        open={modalHandle.setPasswordModal}
        onClose={handleClose}
        centered
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div className="flex items-center justify-center min-h-screen">
          <div className="bg-white p-8 rounded-lg w-96">
            <div className="flex justify-between items-center">
              <div className="mr-2" onClick={handleBack}>
                {<BiArrowBack />}
              </div>
              <h5 className="text-7xl font-semibold">Set New Password</h5>
              <button className="p-2" onClick={handleClose}>
                <TfiClose />
              </button>
            </div>
          <div>
            <Formik
              initialValues={{ otp: "", password: "", confirmpass: "" }}
              onSubmit={(values, { setSubmitting, resetForm }) =>
                onSubmit(values, setSubmitting, resetForm)
              }
              validationSchema={ResetPassSchema}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form>
                  <>
                    <div className="form-group pb-3">
                      {/* <label htmlFor="otp">OTP</label> */}
                      <Field
                        name="otp"
                        className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                        type="text"
                        placeholder="Enter OTP"
                        maxlength="6"
                      />
                      {errors.otp && touched.otp ? (
                        <div className="text-red-400 text-base pl-2">{errors.otp}</div>
                      ) : null}
                    </div>
                    <div className="form-group pb-3">
                      {/* <label htmlFor="password">Password</label> */}
                      <Field
                        name="password"
                        className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                        placeholder="Create New Password"
                        type="password"
                      />
                      {errors.password && touched.password ? (
                        <div className="text-red-400 text-base pl-2">{errors.password}</div>
                      ) : null}
                    </div>
                    <div className="form-group pb-3">
                      {/* <label htmlFor="confirmpass">Confirm Password</label> */}
                      <Field
                        name="confirmpass"
                        placeholder="Confirm New Password"
                        className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                        type="password"
                      />
                      {errors.confirmpass && touched.confirmpass ? (
                        <div className="text-red-400 text-base pl-2">{errors.confirmpass}</div>
                      ) : null}
                    </div>
                    <div className="form-group pt-5">
                      <Button
                        type="submit"
                        className="w-full bg-forestgreen transition-colors duration-300 hover:bg-darkslateblue-100 text-white py-3 rounded-md"
                        disabled={isSubmitting || Object.keys(errors).length > 0}
                      >
                        {isSubmitting ? "Please wait..." : "Submit"}
                      </Button>
                    </div>
                  </>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        </div>
      </Modal>
      {modalHandle.loginModal && (
        <>
          <Signin />
        </>
      )}
    </>
  );
};

export default ResetPassword;
