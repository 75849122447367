import React from "react";
import { useState , useEffect } from "react";
import Assessment from "../Assesement/AssessmentWindow/AssessmentWindow";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { getQuizQuestions } from "../../redux/slices/providerCardSlice";

const TestWindow = () => {
  const dispatch = useDispatch();
  const [startTest, setStartTest] = useState(false);

  const onStartQuiz = () => {
    setStartTest(true);
  }
  useEffect(() => {
    dispatch(getQuizQuestions());
  }, []);
  return (
    (startTest === true) ?
      <div>
        <Assessment />
      </div> :
     <div className="container">
     <div className="row">
       <div className="col-12">
         <div className="d-flex align-items-center justify-content-center">
           <div className="card p-4" style={{ backgroundColor: 'lightblue' }}>
             <h2 className="text-center">Read Instructions Carefully</h2>
             <ul>
               <li>Read each question carefully before answering.</li>
               <li>Select the correct option for each question.</li>
               <li>Click the "Next" button to move to the next question and "Previous" for previous question.</li>
               <li>Click "Submit" button to submit when you are done.</li>
               <li>Time alloted is 30 mins.</li>
               <li>Your score will be displayed at the end of the quiz.</li>
               <li>Do not refresh the page while answering.</li>
             </ul>
             <button className="btn btn-primary btn-block" onClick={onStartQuiz}>
               Start
             </button>
           </div>
         </div>
       </div>
     </div>
   </div>
  );
};

export default TestWindow;