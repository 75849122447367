import React, { useState } from "react";
import { Modal } from "@mui/material";
import _ from "lodash";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";
import ResetPassword from "../ResetPassword/ResetPassword";
import { TfiClose } from "react-icons/tfi";
import { useDispatch, useSelector } from "react-redux";
import { modalManage } from "../../redux/slices/authModalHandleSlice";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { forgotSendOtp } from "../../services/authService";
import {Button } from "@mui/material";

const VerifyPhoneOTP = () => {
  const dispatch = useDispatch();
  const modalHandle = useSelector((state) =>
    _.get(state, "reducer.authModalHandle.modalHandle", {})
  );

  const handleClose = () => {
    dispatch(modalManage("allModalClose"));
  };

  const [phoneNumber, setPhoneNumber] = useState("");

  const handleBack = () => {
    dispatch(modalManage("login"));
  };

  const emailSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email id")
      .required("Email cannot be empty"),
  });

  const onSubmit = async (value) => {
    const reqData = {
      email: _.get(value, "email", ""),
    };
    const response = await forgotSendOtp(reqData).catch((e) =>
      toast.success("Soemthing went worn")
    );
    if (_.get(response, "status") === 1) {
      setPhoneNumber(_.get(value, "email", ""));
      dispatch(modalManage("setPassword"));
      toast.success(_.get(response, "message", ""));
    }
  };

  return (
    <>
      <Modal
        open={modalHandle.forgotModal}
        onCose={handleClose}
        centered
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div className="flex items-center justify-center min-h-screen">
          <div className="bg-white p-8 rounded-lg w-96">
            <div className="flex justify-between items-center">
            <h5 className="text-7xl font-semibold">Verify Email</h5>
              <button className="p-2" onClick={handleClose}>
                <TfiClose />
              </button>
            </div>
            <div>
              <Formik
                initialValues={{ email: "" }}
                onSubmit={(values, { setSubmitting, resetForm }) =>
                  onSubmit(values, setSubmitting, resetForm)
                }
                validationSchema={emailSchema}
              >
                {({ isSubmitting, errors, touched }) => (
                  <Form>
                    <>
                      <div className="form-group">
                        
                        <Field name="email" placeholder="Enter you registered email" className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50" type="email" />
                        {errors.email && touched.email ? (
                          <div  className="text-red-400 text-base pl-2">{errors.email}</div>
                        ) : null}
                      </div>
                      <div className="form-group pt-5">
                        <Button
                          type="submit"
                          className="w-full bg-forestgreen transition-colors duration-300 hover:bg-darkslateblue-100 text-white py-3 rounded-md"
                          disabled={isSubmitting || Object.keys(errors).length > 0}
                        >
                          {isSubmitting ? "Please wait..." : "Submit"}
                        </Button>
                      </div>
                    </>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
      {modalHandle.setPasswordModal && (
        <ResetPassword phoneNumber={phoneNumber} />
      )}
    </>
  );
};

export default VerifyPhoneOTP;
