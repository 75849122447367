import guestAxiosInstance from "../config/guestAxios";

export const signinService = (data) => {
  return guestAxiosInstance.post("/account/login/", data);
};

export const seekerSignUpService = (data) => {
  return guestAxiosInstance.post("/account/seekerregister/", data);
};

export const providerSignUpService = (data) => {
  return guestAxiosInstance.post("/account/providerregister/", data);
};

export const verifyOTP = (data) => {
  return guestAxiosInstance.post("/account/verifymobileotp/", data);
};

export const getMobileOTP = (data) => {
  return guestAxiosInstance.post("/account/getmobileotp/", data);
};

export const setNewPassword = (data) => {
  return guestAxiosInstance.post("/account/resetpassword/", data);
};

export const checkUserValidate = (data) => {
  return guestAxiosInstance.post("/account/check/", data);
};

export const forgotSendOtp = (data) => {
  return guestAxiosInstance.post("/account/forgotpassword/", data);
};
