import Footer from "../../components/footer";
import NormalTop from "../../components/normalTop";

const Team = () => {
    return (
        <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-center gap-[100px] leading-[normal] tracking-[normal] mq800:gap-[25px] mq1150:gap-[50px]">
            <NormalTop heading="OUR TEAM" />
            <section className="relative py-0 box-border w-[full]  text-21xl font-semibold text-darkslateblue-100 text-center inline-block ">
                <div className="flex items-center justify-center pb-20">
                    <div className="flex-wrap items-start justify-center font-dosis max-w-full w-[80%] ">
                        We are a team of passionate development professionals committed to
                        improving the resource landscape of the INDIAN microfinance industry.
                    </div>
                </div>
                <div className="flex items-center justify-center ">
                    <div className="flex lg:flex-row flex-col p-10 lg:gap-[40px]  bg-whitesmoke rounded-xl lg:h-[300px] w-[90%]">
                        <img
                            className="rounded-xl h-[360px] lg:mt-[20px] w-auto transform  -translate-y-1/4"
                            alt="Founder"
                            src="/paras.png"
                        />
                        <div className="flex flex-col items-start justify-start gap-[30px] text-black sm:mt-[-30px]">
                            <b className="relative lg:text-45xl font-dosis">Note from Our Founder</b>
                            <div className="flex flex-col items-start justify-start gap-[15px] text-3xl font-raleway">
                                <div className=" relative leading-[150%] text-left font-medium inline-block">
                                    Resolab is a talent marketplace for the microfinance industry,
                                    connecting leading organizations with the best resources. We believe
                                    that the right talent can transform the microfinance industry, and
                                    we are here to make that happen.
                                </div>
                                <b className=" leading-[150%] z-[1] inline-block">
                                    - Paras Kumar
                                </b>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="relative self-stretch flex flex-row items-center justify-center mt-20 box-border max-w-full text-13xl text-black font-raleway">
                    <div className="h-full w-full flex flex-row flex-wrap items-center justify-center relative gap-4 max-w-full">
                        <div className=" flex flex-col items-center">
                            <img
                                className="object-top rounded-xl h-[350px] w-[300px] object-cover transform"
                                alt=""
                                src="/abhinav.png"
                            />
                            <div className="flex flex-col items-center justify-start gap-[20px] p-10 bg-whitesmoke rounded-xl mt-[-40px] w-[350px] h-[480px] ">
                                <b className="relative font-dosis text-10xl pt-5">Abhinav Mishra</b>
                                <div className="flex flex-col items-center justify-start gap-[20px] text-center text-3xl font-raleway">
                                    <div className=" relative leading-[150%] font-medium inline-block">
                                        Abhinav has worked with mid tier to large scale multi national companies and he helped growth of some of the largest organization in Europe and USA. In his last stint he was managing a team of professionals in India delivering cutting edge technology solutions to DP DHL.
                                    </div>
                                    <b className=" relative leading-[150%] inline-block">
                                        Director
                                    </b>
                                </div>
                            </div>
                        </div>
                        <div className=" flex flex-col items-center">
                            <img
                                className="rounded-xl h-[350px] w-[300px] object-top object-cover transform "
                                alt=""
                                src="/rupal.png"
                            />
                            <div className=" flex flex-col items-center justify-start gap-[20px] p-10 bg-whitesmoke rounded-xl mt-[-40px] w-[350px] h-[480px]">
                                <b className="relative font-dosis text-10xl pt-5">Rupal Shah</b>
                                <div className="flex flex-col items-center justify-start gap-[20px] text-center text-3xl font-raleway">
                                    <div className=" relative leading-[150%] font-medium inline-block">
                                        A passionate academician with two decades of teaching experience ,shaping the careers of more than 3000 students in the field of Information Technology . She has been a subject matter expert in Data Structure, Operating Systems & variety of Programming language .
                                    </div>
                                    <b className=" relative leading-[150%] inline-block">
                                        Director<br></br> (Learning & Development)
                                    </b>
                                </div>
                            </div>
                        </div>
                        <div className=" flex flex-col items-center">
                            <img
                                className="object-top rounded-xl h-[350px] w-[300px] object-cover transform "
                                alt=""
                                src="/urvi.png"
                            />
                            <div className="flex flex-col items-center justify-start gap-[20px] p-10 bg-whitesmoke rounded-xl mt-[-40px] w-[350px] h-[480px]">
                                <b className="relative font-dosis text-10xl pt-5">Urvi Gadhvi</b>
                                <div className="flex flex-col items-center justify-start gap-[20px] text-center text-3xl font-raleway">
                                    <div className=" relative leading-[150%] font-medium inline-block">
                                        Seasoned Sales Professional with more than 12 years of experience in BFSI industries with large public  and private  banks viz. Bank of India, Bank Of Baroda, ICICI Bank &  HDFC Bank . She was into distribution , Training and channel development of financial products.
                                    </div>
                                    <b className=" relative leading-[150%] inline-block">
                                        Operation Manager
                                    </b>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </section>
            <Footer
                image58="/image-581@2x.png"
                image60="/image-60@2x.png"
                image581="/image-58-1@2x.png"
                image601="/image-60@2x.png"
            />
        </div>
    );
};

export default Team;
