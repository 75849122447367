import React, { useState } from "react";
import { Button } from "@mui/material";
import { Modal } from "@mui/material";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { TfiClose } from "react-icons/tfi";
import {
  checkUserValidate,
  providerSignUpService,
  seekerSignUpService,
  verifyOTP,
} from "../../../services/authService";
import _ from "lodash";
import Signin from "../Sign-in/Signin";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiArrowBack } from "react-icons/bi";
import PhoneInput from "react-phone-number-input";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../firebase";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useEffect } from "react";

const Signup = ({ signupShow, setSignupShow }) => {
  const [loginShow, setLoginShow] = useState(false);
  const [jobForm, setJobForm] = useState(false);
  const [hireTechForm, sethireTechForm] = useState(false);
  const [otpForm, setOtpForm] = useState(false);
  const [isForm, setIsForm] = useState("jobform");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [inputPhone, setInputPhone] = useState("");
  const [verificationResponse, setVerificationResponse] = useState("");
  const [confirmResponse, setConfirmResponse] = useState("");
  const [getOtpFlag, setGetOtpFlag] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sendOTPloading, setSendOTPloading] = useState(false);
  const [changeSeekerText, setChangeSeekerText] = useState(
    "I want to hire a talent"
  );
  const [changeProviderText, setChangeProviderText] = useState(
    "I'm looking for a job"
  );
  const [changeProviderCss, setChangeProviderCss] = useState({
    width: "100%",
    color: "#343a40",
    borderColor: "#343a40",
  });

  const [otpVerify, setOtpVerify] = useState("");
  const [reqDataForRegsitration, setReqDataForRegsitration] = useState({});

  const handleLoginShow = () => setLoginShow(true);

  const handleClose = () => {
    setSignupShow(false);
    setJobForm(false);
    sethireTechForm(false);
    setOtpForm(false);
  };

  const handleJob = () => setJobForm(true);

  const handleHireTech = () => sethireTechForm(true);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const SignupSchema = hireTechForm
    ? Yup.object().shape({
      fname: Yup.string()
        .min(2, "Firstname is too short!")
        .max(50, "Firstname is too long!")
        .required("Firstname can not be empty"),
      lname: Yup.string()
        .min(2, "Lastname is too short!")
        .max(50, "Lastname is too long!")
        .required("Lastname can not be empty"),
      company_name: Yup.string()
        .min(2, "Company name is too short!")
        .max(50, "Company name is too long!")
        .required("Company name can not be empty"),
      // industry: Yup.string().required("Industry can not be empty"),
      email: Yup.string()
        .email("Invalid email id")
        .required("Email can not be empty"),
      password: Yup.string()
        .required("Password can not be empty")
        .min(8, "Password must be 8 characters or more")
        .matches(/[a-z]+/, "One lowercase character")
        .matches(/[A-Z]+/, "One uppercase character")
        .matches(/[@$!%*#?&]+/, "One special character")
        .matches(/\d+/, "One number"),
      confirmpass: Yup.string()
        .required("Confirm password can not be empty")
        .oneOf([Yup.ref("password")], "Your passwords do not match."),
      phone: Yup.string()
        .required("Phone number can not be empty")
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Phone number is too short")
        .max(10, "Phone number is too long"),
    })
    : Yup.object().shape({
      fname: Yup.string()
        .min(2, "Firstname is too short!")
        .max(50, "Firstname is too long!")
        .required("Firstname can not be empty"),
      lname: Yup.string()
        .min(2, "Lastname is too short!")
        .max(50, "Lastname is too long!")
        .required("Lastname can not be empty"),
      email: Yup.string()
        .email("Invalid email id")
        .required("Email can not be empty"),
      password: Yup.string()
        .required("Password  can not be empty")
        .min(8, "Password must be 8 characters or more")
        .matches(/[a-z]+/, "One lowercase character")
        .matches(/[A-Z]+/, "One uppercase character")
        .matches(/[@$!%*#?&]+/, "One special character")
        .matches(/\d+/, "One number"),
      confirmpass: Yup.string()
        .required("Confirm password can not be empty")
        .oneOf([Yup.ref("password")], "Your passwords do not match."),
      phone: Yup.string()
        .required("Phone number can not be empty")
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Phone number is too short")
        .max(10, "Phone number is too long"),
    });

  const OTPSchema = Yup.object().shape({
    // otp: Yup.string().required("required").max(4, "too long"),
  });

  const onSubmit = async (values, setSubmitting) => {
    let reqDataForRegister;
    if (hireTechForm) {
      reqDataForRegister = {
        first_name: _.get(values, "fname", ""),
        last_name: _.get(values, "lname", ""),
        password: _.get(values, "password", ""),
        password2: _.get(values, "confirmpass", ""),
        email: _.get(values, "email", ""),
        phone_number: _.get(values, "phone", ""),
        // industry: _.get(values, "industry", ""),
        company_name: _.get(values, "company_name", ""),
        // other_industry: _.get(values, "other_industry", ""),
      };
    } else {
      reqDataForRegister = {
        first_name: _.get(values, "fname", ""),
        last_name: _.get(values, "lname", ""),
        password: _.get(values, "password", ""),
        password2: _.get(values, "confirmpass", ""),
        email: _.get(values, "email", ""),
        phone_number: _.get(values, "phone", ""),
      };
    }


    const reqData = {
      email: _.get(values, "email", ""),
      phone_number: _.get(values, "phone", ""),
    };

    const response = await checkUserValidate(reqData).catch((err) =>
      console.log(err)
    );
    if (_.get(response, "Status", "") === 1) {
      if (hireTechForm) {
        setJobForm(false);
        sethireTechForm(false);
        setOtpForm(true);
        setIsForm("hiretechform");
        setPhoneNumber(_.get(values, "phone", ""));
        setReqDataForRegsitration(reqDataForRegister);
        // await getOtp();
      } else {
        setJobForm(false);
        sethireTechForm(false);
        setOtpForm(true);
        setIsForm("jobform");
        setPhoneNumber(_.get(values, "phone", ""));
        setReqDataForRegsitration(reqDataForRegister);
        // await getOtp();
      }
    } else {
      toast.error(_.get(response, "message", ""));
    }
  };

  async function setUpRecaptcha() {
    const formatPh = "+91" + phoneNumber;
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "sign-in-button",
        { size: "invisible" },
        auth
      );
      window.recaptchaVerifier.render();
      return signInWithPhoneNumber(auth, formatPh, window.recaptchaVerifier);
    } else {
      return signInWithPhoneNumber(auth, formatPh, window.recaptchaVerifier);
    }
  }

  useEffect(() => {
    if (otpForm) {
      document.getElementById("buttonDiv").click();
    }
  }, [otpForm]);

  const getOtp = async (e) => {
    setSendOTPloading(true);
    const formatPh = "+91" + phoneNumber;
    if (formatPh === "" || formatPh === undefined) {
      toast.error("Invalid phone number..!");
    } else {
      try {
        const response = await setUpRecaptcha(formatPh);
        if (_.get(response, "verificationId", "")) {
          toast.success("Successfully OTP send..!");
          setVerificationResponse(response);
          setGetOtpFlag(false);
        } else {
          toast.error("Invalid phone number..!");
          setGetOtpFlag(true);
        }
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      }
    }
    setSendOTPloading(false);
  };

  const onOTPVerify = async () => {
    setLoading(true);
    let myres = await verificationResponse.confirm(otpVerify).catch((e) => {
      toast.error("Invalid OTP..!");
    });
    if (myres) {
      switch (isForm) {
        case "jobform":
          const response = await providerSignUpService(
            reqDataForRegsitration
          ).catch((err) =>
            toast.error(
              _.get(err, "message.errors.errors[0]", "").split(":")[1]
            )
          );
          if (_.get(response, "data.status", 0) === 1) {
            toast.success(_.get(response, "data.message", ""));
            setJobForm(false);
            sethireTechForm(false);
            setOtpForm(false);
            setSignupShow(false);
            handleLoginShow();
          } else {
            toast.success("something went wrong..!");
          }
          break;

        case "hiretechform":
          const response1 = await seekerSignUpService(
            reqDataForRegsitration
          ).catch((err) =>
            toast.error(
              _.get(err, "message.errors.errors[0]", "").split(":")[1]
            )
          );
          if (_.get(response1, "data.status", 0) === 1) {
            toast.success(_.get(response1, "data.message", ""));
            setJobForm(false);
            sethireTechForm(false);
            setOtpForm(false);
            setSignupShow(false);
            handleLoginShow();
          } else {
            toast.success("something went wrong..!");
          }
          break;
        default:
          break;
      }
    } else {
      // toast.error("Invalid OTP..!");
    }
    setLoading(false);
  };

  const resendOtp = async () => {
    const formatPh = "+91" + phoneNumber;
    let res = await signInWithPhoneNumber(
      auth,
      formatPh,
      window.recaptchaVerifier
    );
    if (_.get(res, "verificationId", "")) {
      toast.success("Successfully OTP resend..!");
      setVerificationResponse(res);
    } else {
      toast.error("Invalid phone number..!");
    }
  };

  const getJobForm = (isSubmitting, errors, touched) => {
    let formDefine = (
      <div className="flex flex-col gap-[5px]">
        <div className="form-group mt-4">

          <Field name="fname" placeholder="First Name" className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50" type="text" />
          {errors.fname && touched.fname ? (
            <div className="text-base pl-2" style={{ color: "red" }} >{errors.fname}</div>
          ) : null}
        </div>
        <div className="form-group">
          <Field name="lname" placeholder="Last Name" className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50" type="text" />
          {errors.lname && touched.lname ? (
            <div className="text-base pl-2" style={{ color: "red" }} >{errors.lname}</div>
          ) : null}
        </div>
        <div className="form-group">
          <Field name="email" placeholder="Enter your Email " className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50" type="text" />
          {errors.email && touched.email ? (
            <div className="text-base pl-2" style={{ color: "red" }} >{errors.email}</div>
          ) : null}
        </div>
        <div className="form-group">

          <Field name="password" placeholder="Create Password" className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50" type="password" />
          {errors.password && touched.password ? (
            <div className="text-base pl-2" style={{ color: "red" }} >{errors.password}</div>
          ) : null}
        </div>
        <div className="form-group">
          {/* <label htmlFor="confirmpass">
            Confirm Password <span className="text-danger">*</span>
          </label> */}
          <Field name="confirmpass" placeholder="Confirm Password" className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50" type="password" />
          {errors.confirmpass && touched.confirmpass ? (
            <div className="text-base pl-2" style={{ color: "red" }} >{errors.confirmpass}</div>
          ) : null}
        </div>
        <div className="form-group">

          <Field
            name="phone"
            placeholder="Phone number"
            className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
            type="text"
            maxlength="10"
          />
          {errors.phone && touched.phone ? (
            <div className="text-base pl-2" style={{ color: "red" }} >{errors.phone}</div>
          ) : null}
        </div>
        <div className="form-group pt-5">
          <Button
            type="submit"
            className="w-full bg-forestgreen transition-colors duration-300 hover:bg-darkslateblue-100 text-white py-3 rounded-md"
            disabled={isSubmitting || Object.keys(errors).length > 0}
          >
            {isSubmitting ? "Please wait..." : "Submit"}
          </Button>
        </div>
      </div>
    );
    return formDefine;
  };

  const getHireTechForm = (isSubmitting, errors, touched) => {
    let formDefine = (
      <div className="flex flex-col gap-[5px]">
        <div className="form-group">
          
          <Field name="fname" placeholder="First Name" className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50" type="text" />
          {errors.fname && touched.fname ? (
            <div className="text-base pl-2" style={{ color: "red" }} >{errors.fname}</div>
          ) : null}
        </div>
        <div className="form-group">
          
          <Field name="lname" placeholder="Last Name" className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50" type="text" />
          {errors.lname && touched.lname ? (
            <div className="text-base pl-2" style={{ color: "red" }} >{errors.lname}</div>
          ) : null}
        </div>
        <div className="form-group">
          {/* <label htmlFor="company_name">
            Company Name <span className="text-danger">*</span>
          </label> */}
          <Field name="company_name" placeholder="Company Name" className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50" type="text" />
          {errors.company_name && touched.company_name ? (
            <div className="text-base pl-2" style={{ color: "red" }} >{errors.company_name}</div>
          ) : null}
        </div>

        <div className="form-group">
          {/* <label htmlFor="email">
            Email <span className="text-danger">*</span>
          </label> */}
          <Field name="email" placeholder="Enter your Email" className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50" type="text" />
          {errors.email && touched.email ? (
            <div className="text-base pl-2" style={{ color: "red" }} >{errors.email}</div>
          ) : null}
        </div>
        <div className="form-group">
          {/* <label htmlFor="password">
            Password <span className="text-danger">*</span>
          </label> */}
          <Field name="password" placeholder="Create Password" className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50" type="password" />
          {errors.password && touched.password ? (
            <div className="text-base pl-2" style={{ color: "red" }} >{errors.password}</div>
          ) : null}
        </div>
        <div className="form-group">
          {/* <label htmlFor="confirmpass">
            Confirm Password <span className="text-danger">*</span>
          </label> */}
          <Field name="confirmpass" placeholder="Confirm Password" className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50" type="password" />
          {errors.confirmpass && touched.confirmpass ? (
            <div className="text-base pl-2" style={{ color: "red" }} >{errors.confirmpass}</div>
          ) : null}
        </div>
        <div className="form-group">
          {/* <label htmlFor="phone">
            Phone number <span className="text-danger">*</span>
          </label> */}
          <Field
            name="phone"
            placeholder="Phone No." className="mt-1 block w-full h-10 px-2 text-1xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
            type="text"
            maxlength="10"
          />
          {errors.phone && touched.phone ? (
            <div className="text-base" className="text-base pl-2" style={{ color: "red" }} >{errors.phone}</div>
          ) : null}
        </div>
        <div className="form-group pt-5">
          <Button
            type="submit"
            className="w-full bg-forestgreen transition-colors duration-300 hover:bg-darkslateblue-100 text-white py-3 rounded-md"
            disabled={isSubmitting || Object.keys(errors).length > 0}
          >
            {isSubmitting ? "Please wait..." : "Submit"}
          </Button>
        </div>
      </div>
    );
    return formDefine;
  };

  const getOTPForm = (isSubmitting, errors, touched) => {
    let formDefine = (
      <>
        <div className="form-group py-4">
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone Number*</label>
          <div className="flex items-center gap-[10px]">
            <PhoneInput
              className="form-control my-1 w-full bg-whitesmoke h-10 px-2 text-7xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
              defaultCountry="IN"
              placeholder="Enter Your Phone Number"
              value={phoneNumber}
              disabled
            />
            <div id="sign-in-button"></div>
            {getOtpFlag && (
              <div
                id="buttonDiv"
                className="btnOTP"
                onClick={getOtp}
                style={{ display: "none" }}
              >
                {sendOTPloading ? "OTP sending..!" : "Get OTP"}
              </div>
            )}
          </div>
        </div>
        <div className="form-group py-4">
          <label htmlFor="otp" className="block text-3xl font-medium text-gray-700">OTP*</label>
          <OTPInput
            className="form-control my-1 py-1 bg-whitesmoke w-full h-10 px-2 text-7xl rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
            value={otpVerify}
            onChange={setOtpVerify}
            autoFocus
            OTPLength={6}
            otpType="number"
            disabled={false}
          />
          <div className="form-group my-6 ">
            {!getOtpFlag && <ResendOTP className="py-3 text-3xl"  onResendClick={resendOtp} />}
          </div>
        </div>
        <div className="form-group">
          <Button
            className={`btnOTP btn bg-forestgreen transition-colors duration-300 hover:bg-darkslateblue-100 text-white w-full py-3 btn-primary ${otpVerify.length === 6 ? '' : 'opacity-50 cursor-not-allowed'}`}
            onClick={onOTPVerify}
            disabled={otpVerify.length === 6 ? false : true}
          >
            {loading ? "Please wait..." : "Verify OTP"}
          </Button>
        </div>
      </>

    );
    return formDefine;
  };

  const onMouseSeekereover = (e) => {
    setChangeSeekerText("Resource Seeker");
  };

  const onMouseSeekerout = (e) => {
    setChangeSeekerText("I want to hire a talent");
  };

  const onMouseProviderOver = (e) => {
    setChangeProviderCss({ width: "100%" });
    setChangeProviderText("Resource Provider");
  };

  const onMouseProviderOut = (e) => {
    setChangeProviderCss({
      width: "100%",
      color: "#343a40",
      borderColor: "#343a40",
    });

    setChangeProviderText("I'm looking for a job");
  };

  const getSelectonRoles = () => {
    let selectionBtn = (
      <div className="d-grid gap-2" data-hover="IT WORKS">
        <Button
          variant="outline-success"
          size="lg"
          style={changeProviderCss}
          onClick={handleJob}
          className="mb-3 py-4 text-darslateblue-100 hover:bg-darkslateblue-100 hover:text-white"
          onMouseEnter={onMouseProviderOver}
          onMouseLeave={onMouseProviderOut}
        >
          {changeProviderText}
        </Button>
        <Button
          variant="outline-dark"
          size="lg"
          style={{ width: "100%" }}
          onClick={handleHireTech}
          className="mb-3 py-4 text-forestgreen hover:bg-forestgreen hover:text-white"
          onMouseEnter={onMouseSeekereover}
          onMouseLeave={onMouseSeekerout}
        >
          {changeSeekerText}
        </Button>
      </div>
    );
    return selectionBtn;
  };

  const handleBack = () => {
    switch (isForm) {
      case "jobform":
        setOtpForm(false);
        setGetOtpFlag(true);
        setJobForm(true);
        break;
      case "hiretechform":
        setOtpForm(false);
        setGetOtpFlag(true);
        sethireTechForm(true);
        break;
      default:
        break;
    }
  };

  const handleDefaultForm = () => {
    setJobForm(false);
    sethireTechForm(false);
    setOtpForm(false);
    setChangeProviderText("I'm looking for a job");
    setChangeProviderCss({
      width: "100%",
      color: "#343a40",
      borderColor: "#343a40",
    });
    setChangeSeekerText("I want to hire a talent");
  };

  return (
    <>
      <Modal
        open={signupShow}
        onClose={handleClose}
        centered
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div className="flex items-center justify-center min-h-screen">
          <div className="bg-white p-8 rounded-lg w-96">
            <div className="flex justify-between items-center text-9xl font-semibold pb-5">
              <div
                className="mr-2 "
                onClick={otpForm ? handleBack : handleDefaultForm}
              >
                {(hireTechForm || jobForm) && <BiArrowBack />}
              </div>
              {!otpForm ? "SIGN UP" : "Verify Phone No."}

              <span
                onClick={handleClose}
                className="px-3"
                style={{ fontSize: "20px", color: "black", cursor: "pointer" }}
              >
                <TfiClose />
              </span>
            </div>
            <div>
              <Formik
                initialValues={{
                  fname: "",
                  lname: "",
                  company_name: "",
                  email: "",
                  password: "",
                  confirmpass: "",
                  phone: "",
                }}
                onSubmit={(values, { setSubmitting, resetForm }) =>
                  onSubmit(values, setSubmitting)
                }
                validationSchema={!otpForm ? SignupSchema : OTPSchema}
              >
                {({ isSubmitting, errors, touched }) => (
                  <Form>
                    {jobForm && getJobForm(isSubmitting, errors, touched)}
                    {hireTechForm && getHireTechForm(isSubmitting, errors, touched)}
                    {otpForm && getOTPForm(isSubmitting, errors, touched)}
                    {!hireTechForm && !jobForm && !otpForm && getSelectonRoles()}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
      {loginShow && (
        <Signin
          handleLoginShow={handleLoginShow}
          loginShow={loginShow}
          setLoginShow={setLoginShow}
        />
      )}
    </>
  );
};

export default Signup;
