import Navbar1 from "./navbar";

const NormalTop = ({heading}) => {
  return (
    <section className="self-stretch bg-darkslateblue-100 flex flex-col items-center justify-center pb-[30px] lg:pb-[150px]  pt-0 lg:px-5 box-border gap-[120px]  text-center text-45xl text-white font-dosis mq800:gap-[60px]  mq800:box-border mq450:gap-[30px]">
      <Navbar1 />
      <div className="w-full  items-start justify-start py-0 px-[45px] box-border w-full ">
        <div className="items-start justify-start relative max-w-full">
          <img
            className="lg:h-[350px]  h-[0px] w-[full] absolute  bottom-[-150px] left-[-50px] z-[1] "
            loading="lazy"
            alt="wave"
            src="/image-1@2x.png"
          />
          <h3 className="m-0 text-heading font-bold mq800:text-32xl mq450:text-15xl">
            {heading}
          </h3>
        </div>
      </div>
    </section>
  );
};

export default NormalTop;
