import Routing from "./routes";
import ProviderRouter from "./routes/ProviderRoute";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import { ToastContainer } from "react-toastify";
import { getCookie, removeCookie } from "./config/cookies";
import { useSelector } from "react-redux";
import _, { set } from "lodash";
import SeekerRouter from "./routes/SeekerRoute";
import { useEffect } from "react";
import { ClearAuthSign } from "./redux/slices/authSlice";
import { useDispatch } from "react-redux";

export default function App() {
  const token = getCookie("authToken");
  const dispatch = useDispatch();
  const expiresIn = getCookie("expiresIn");
  const userDetail = useSelector((state) => _.get(state, "reducer.auth", {}));
  const isProviderRole = _.get(userDetail, "user.is_provider", false);
  useEffect(() => {
    if (expiresIn < Date.now()) {
      window.location.href = "/";
      removeCookie("authToken");
      removeCookie("isSeeker");
      removeCookie("expiresIn");
      removeCookie("isProvider");
      dispatch(ClearAuthSign());
    }
  }, [expiresIn]);
  return (
    <Router>
      {!token ? (
        <Routing />
      ) : isProviderRole ? (
        <ProviderRouter />
      ) : (
        <SeekerRouter />
      )}
      <ToastContainer />
    </Router>
  );
}
