import { current } from "@reduxjs/toolkit";
import axiosInstance from "../config/axios";
import axios from "axios";

export const getProviderCard = (page) => {
  return axiosInstance.get(`/provider/providercardlist/?page=${page}`);
};

export const getProviderCardWithSearch = (jobRoles, stateId, page) => {
  return axiosInstance.get(
    `/provider/providercardlist/?job_roles=${jobRoles}&current_work_state=${stateId}&page=${page}`
  );
};

export const addResourceCard = (data) => {
  return axiosInstance.post("/provider/providercard/", data);
};

export const editResourceCard = (id, data) => {
  return axiosInstance.put(`/provider/resourceprovidercard/${id}/`, data);
};

export const getResourceCategories = () => {
  return axiosInstance.get("/jobs/jobcategory/");
};

export const getAllState = () => {
  return axiosInstance.get("/provider/state/");
};

export const getAllDistrict = (stateId) => {
  return axiosInstance.get(`/provider/Distlist/?state=${stateId}`);
};

export const getProfile = (id) => {
  return axiosInstance.get(`/account/userprofile/${id}`);
};

// export const editProfile = (id, data) => {
//   return axiosInstance.put(`/account/profile/${id}/`, data);
// };
export const editProfile = (id, data) => {
  return axiosInstance.put(`/account/profile/update/${id}/`, data);
};

export const postCV = (data) => {
  return axiosInstance.post("/provider/resume/", data);
};

export const getCV = () => {
  return axiosInstance.get("/provider/resume/");
};

export const getCVByUserId = (userId) => {
  return axiosInstance.get(`/provider/userresume/?user=${userId}`);
};

export const providerSearch = (jobRoles, stateId) => {
  return axiosInstance.get(
    `/provider/reslist?job_roles=${jobRoles}&current_work_state=${stateId}`
  );
};

export const singleProviderResoCard = () => {
  return axiosInstance.get("/provider/providercard/");
};

export const deleteEducation = (id) => {
  return axiosInstance.delete(`/provider/education/${id}/`);
};

export const deleteEmplyment = (id) => {
  return axiosInstance.delete(`/provider/experience/${id}/`);
};

export const getProviderCardById = (id) => {
  return axiosInstance.get(`/provider/pcard/${id}`);
};

export const getAllJobRoles = (catId) => {
  return axiosInstance.get(`/provider/joblist/?category=${catId}`);
};

export const getHomeCount = () => {
  return axiosInstance.get("/provider/rescount/");
};

export const connectProviderCard = (data) => {
  return axiosInstance.post(`/provider/connect/`, data);
};


export const getAppliedJobsByUserId = (currentPage) => {
  return axiosInstance.get(`/provider/applied-jobs/?page=${currentPage}`);
}

export const getQuestionsOfQuiz = (data) => {
  return axiosInstance.post(`/assessment/start-assessment/`,data);
}

export const submitAssessment = (data) => {
  return axiosInstance.post(`/assessment/submit-assessment/`,data);
}

// submit create assessment

export const createAssessment = (data) => {
  return axiosInstance.post(`/assessment/pre-job-assessment/create/`,data);
}

export const getQuestionsOfPreJob = (data) => {
  return axiosInstance.post(`/assessment/pre-job-assessment/start/`,data);
}

export const getPartnersDetails = () => {
  return axiosInstance.get('/seeker/mfi-images/');
}

export const awsUpdateImage1 = (data) => {
  return axiosInstance.post('/account/get-presigned-data/', data);
}

export const awsUpdateImage2 = (data) => {
  return axios.post('https://resolab-media.s3.amazonaws.com/', data );
}

export const uploadProviderDocs = (data) => {
  return axiosInstance.post('/provider/upload-docs/', data);
}

export const deleteEduDocs = (id) => {
  return axiosInstance.delete(`/provider/delete-edu-docs/${id}/`);
}

export const deleteExpDocs = (id) => {
  return axiosInstance.delete(`/provider/delete-exp-docs/${id}/`);
}