import React, { useState } from "react";
import { Navbar, Collapse, Typography, IconButton, List, ListItem } from "@material-tailwind/react";
import {
    Bars3Icon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import Signin from "../pages/Auth/Sign-in/Signin";
import Signup from "../pages/Auth/Sign-up/Signup";
import { modalManage } from "../redux/slices/authModalHandleSlice";
import { Button } from "@mui/material";
import { ClearAuthSign } from "../redux/slices/authSlice";
import {
    clearAllproviderSearchCardTabChange,
} from "../redux/slices/providerCardSlice";
import {
    clearAllSearchCardAfterTabChange,
} from "../redux/slices/seekerCardSlice";
import { getCookie, removeCookie } from "../config/cookies";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";



function NavList() {
    const navigate = useNavigate();
    const onTextClick = (path) => {
        navigate(path);
    }
    return (
        <List className="self-stretch flex flex-col items-start justify-start  gap-[5px] py-2 text-darkslateblue-100 mx-5 lg:text-white font-raleway lg:my-0 lg:mx-0 lg:flex-row lg:p-1 lg:gap-[10px]">
            <Typography
                className="cursor-pointer text-lg py-0" onClick={() => onTextClick("/home")}
            >
                <ListItem className="flex items-center  py-0 px-0">Home</ListItem>
            </Typography>
            <Typography
                className="cursor-pointer text-lg py-0" onClick={() => onTextClick("/resourceavailable")}
            >
                <ListItem className="flex items-center  py-0 px-0">Resource Available</ListItem>
            </Typography>
            <Typography
                className="cursor-pointer text-lg py-0" onClick={() => onTextClick("/resourcerequire")}
            >
                <ListItem className="flex items-center  py-0 px-0">Resource Require</ListItem>
            </Typography>
            <Typography
                className="cursor-pointer text-lg py-0" onClick={() => onTextClick("/assesement")}
            >
                <ListItem className="flex items-center  py-0 px-0">Assessment</ListItem>
            </Typography>
            <Typography
                className="cursor-pointer text-lg py-0" onClick={() => onTextClick("/price")}
            >
                <ListItem className="flex items-center  py-0 px-0">Pricing</ListItem>
            </Typography>
            <Typography
                className="cursor-pointer text-lg py-0" onClick={() => onTextClick("/faq")}
            >
                <ListItem className="flex items-center  py-0 px-0">FAQs</ListItem>
            </Typography>
        </List>
    );
}

const Navbar1 = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [language, setLanguage] = useState('en');
    const [openNav, setOpenNav] = React.useState(false);

    const navigation = () => {
        if (_.get(userDetail, "user.is_provider", false)) {
            navigate("/provider");
        }
        else {
            navigate("/seeker");
        }
    }

    const handleLoginShow = () => {
        dispatch(modalManage("login"));
    };

    const [signupShow, setSignupShow] = useState(false);
    const handleSignupShow = () => setSignupShow(true);

    const token = getCookie("authToken");

    const userDetail = useSelector((state) => _.get(state, "reducer.auth", {}));
    const handleLogOut = () => {
        window.location.href = "/";
        removeCookie("authToken");
        removeCookie("isSeeker");
        removeCookie("isProvider");
        removeCookie("expiresIn");
        dispatch(ClearAuthSign());
    };
    const queryParameters = window.location.pathname;

    const clearCard = () => {
        dispatch(clearAllSearchCardAfterTabChange());
        dispatch(clearAllproviderSearchCardTabChange());
    };

    const clearRequireCard = () => {
        dispatch(clearAllSearchCardAfterTabChange());
    };

    const clearAvailableCard = () => {
        dispatch(clearAllproviderSearchCardTabChange());
    };

    React.useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 800 && setOpenNav(false),
        );
    }, []);

    return (
        <>
            <Navbar className="relative rounded-0xl lg:w-3/4 w-full lg:bg-transparent z-[2] pr-5 lg:pr-0 lg:p-0">
                <div className="flex items-center justify-between text-black ">
                    <div className="self-stretch w-[auto] rounded-t-none rounded-b-xl bg-white flex flex-col items-center justify-center box-border px-5 pt-2 pb-2 lg:py-5 ">
                        <img
                            className="relative max-w-[150px] lg:py-5 max-h-[100px] overflow-hidden object-cover"
                            loading="lazy"
                            alt=""
                            src="/image-64@2x.png"
                        />
                    </div>
                    <div className="hidden  lg:block">
                        <NavList />
                    </div>
                    <div className="hidden gap-2 lg:flex text-white">
                        {token ? (
                            <>
                                <Button variant="button" className="bg-darkslateblue-100 text-xl py-3" onClick={() => navigation()}>
                                    DASHBOARD
                                </Button>
                                <Button variant="button" className="bg-forestgreen text-xl py-3" onClick={handleLogOut}>
                                    LOGOUT
                                </Button>
                            </>
                        )
                            :
                            (
                                <>
                                    <Button variant="button" className="bg-darkslateblue-100 py-1 text-3xl font-raleway font-bold hover:bg-forestgreen " onClick={handleLoginShow}>
                                        LOGIN
                                    </Button>
                                    <Button variant="button" className="bg-forestgreen  py-1 text-3xl font-raleway font-bold hover:bg-darkslateblue-100" onClick={handleSignupShow}>
                                        SIGN UP
                                    </Button>
                                </>
                            )}
                    </div>
                    <IconButton
                        variant="text"
                        color="blue-gray"
                        className="lg:hidden px-5"
                        onClick={() => setOpenNav(openNav == false ? true : false)}
                    >
                        {openNav ? (
                            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
                        ) : (
                            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
                        )}
                    </IconButton>
                </div>
                <Collapse open={openNav} >
                    <NavList />
                    <div className="flex w-full flex-nowrap items-center gap-2 lg:hidden pl-4 pb-4">
                        {token ? (
                            <>
                                <Button variant="button" className="bg-darkslateblue-100  py-2" fullWidth onClick={() => navigation()}>
                                    DASHBOARD
                                </Button>
                                <Button variant="button" className="bg-forestgreen  py-2" fullWidth onClick={handleLogOut}>
                                    LOGOUT
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button variant="button" className="bg-darkslateblue-100 py-2" fullWidth onClick={handleLoginShow}>
                                    LOGIN
                                </Button>
                                <Button variant="button" className="bg-forestgreen py-2" fullWidth onClick={handleSignupShow}>
                                    SIGN UP
                                </Button>
                            </>
                        )}
                    </div>
                </Collapse>
            </Navbar>
            <Signin />
            <Signup
                handleSignupShow={handleSignupShow}
                signupShow={signupShow}
                setSignupShow={setSignupShow}
            />
        </>
    );
}

export default Navbar1;