import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import { signinService } from "../../services/authService";
import { setCookie } from "../../config/cookies";
import { modalManage } from "../../redux/slices/authModalHandleSlice";

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    usersSuccess: (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
      state.isAuthenticated = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
  },
});

// Reducer
export default authSlice.reducer;

//Action
const { usersSuccess, startLoading, stopLoading } = authSlice.actions;

export const AuthSign = (reqData, navigate) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await signinService(reqData).catch((err) => {
      dispatch(modalManage("allModalClose"));
      Swal.fire({
        icon: "info",
        title: (_.get(err, "message.errors.[0]", "") === "Forbidden" | _.get(err, "message.errors.[0]", "") === "Bad request") ? "Invalid Credentials" : _.get(err, "message.errors.[0]", ""),
        showCloseButton: true,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok!',
      })
    }

      // toast.error(_.get(err, "message.errors.[0]", "").split(":")[1])
    );

    // console.log(response , "response");
    if (_.get(response, "status", 0) === 1) {
      toast.success(_.get(response, "message", ""));
      setCookie("authToken", _.get(response, "data.tokens.access", ""));
      setCookie("expiresIn", Date.now() + 1000 * 60 * 60 * 24);
      setCookie("is_seeker", _.get(response, "data.is_seeker", false));
      setCookie("is_provider", _.get(response, "data.is_provider", false));
      dispatch(usersSuccess(_.get(response, "data", [])));
      dispatch(modalManage("allModalClose"));
      if (_.get(response, "data.is_seeker", false) === true) {
        window.location.href = "/seeker";
      } else {
        window.location.href = "/provider";
      }
    }
    dispatch(stopLoading());
  } catch (e) {
    if (e.code === 500) {
      // removeCookie("authToken");
    }
    // console.log(e, "error");
  }
};

export const ClearAuthSign = (reqData, navigate) => async (dispatch) => {
  dispatch(usersSuccess({}));
};
