import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalHandle: {
    loginModal: false,
    forgotModal: false,
    signupModal: false,
    setPasswordModal: false,
    
  }
};

//slice
const authModalHandleSlice = createSlice({
  name: "authModalHandle",
  initialState,
  reducers: {
    // Modal handle
    closeModal(state) {
      state.modalHandle.loginModal = false;
      state.modalHandle.forgotModal = false;
      state.modalHandle.signupModal = false;
      state.modalHandle.setPasswordModal = false;
    },

    loginLoading(state) {
      state.modalHandle.loginModal = true;
      state.modalHandle.forgotModal = false;
      state.modalHandle.signupModal = false;
      state.modalHandle.setPasswordModal = false;
    },

    forgotLoading(state) {
      state.modalHandle.loginModal = false;
      state.modalHandle.forgotModal = true;
      state.modalHandle.setPasswordModal = false;
      state.modalHandle.signupModal = false;
    },

    signupLoading(state) {
      state.modalHandle.loginModal = false;
      state.modalHandle.forgotModal = false;
      state.modalHandle.signupModal = true;
      state.modalHandle.setPasswordModal = false;
    },
    setPasswordModal(state) {
      state.modalHandle.setPasswordModal = true;
      // state.modalHandle.forgotModal = false;
      state.modalHandle.loginModal = false;
      state.modalHandle.signupModal = false;
    },
  },
});

// Reducer
export default authModalHandleSlice.reducer;

//Action
const {
  loginLoading, forgotLoading, signupLoading, closeModal, setPasswordModal
} = authModalHandleSlice.actions;

export const modalManage = (modal) => async (dispatch) => {
  switch (modal) {
    case "login":
      dispatch(loginLoading());
      break;
    case "forgot":
      dispatch(forgotLoading());
      break;
    case "signup":
      dispatch(signupLoading());
      break;
    case "setPassword":
     await dispatch(setPasswordModal());
      break;
    case "allModalClose":
      dispatch(closeModal());
      break;
    default:
      dispatch(closeModal());
  }
};
