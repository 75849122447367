import axiosInstance from "../config/axios";

export const getSeekerCard = (page) => {
  return axiosInstance.get(`/seeker/seekercardlist/?page=${page}`);
};

export const getSeekerCardWithSearch = (jobRoles, jobLocation, page) => {
  return axiosInstance.get(
    `/seeker/seekercardlist/?job_roles=${jobRoles}&state=${jobLocation}&page=${page}`
  );
};

export const seekerSearch = (jobRoles, jobLocation) => {
  return axiosInstance.get(
    `/seeker/seeklist/?job_roles=${jobRoles}&state=${jobLocation}`
  );
};

export const addSeekerResoCard = (data) => {
  return axiosInstance.post("/seeker/seekercard/", data);
};

export const editSeekerResoCard = (id, data) => {
  return axiosInstance.patch(`/seeker/seekercard/${id}/`, data);
};

export const getSeekerCardByUserID = (currentPage) => {
  return axiosInstance.get(`/seeker/seekercard/?page=${currentPage}`);
};

export const getSeekerCardById = (id) => {
  return axiosInstance.get(`/seeker/scard/${id}`);
};

export const deleteSeekerCardById = (id) => {
  return axiosInstance.delete(`/seeker/seekercard/${id}`);
};

export const getSeekerCardCount = () => {
  return axiosInstance.get(`/seeker/sekcardcount/`);
};

export const addInquiryDetails = (data) => {
  return axiosInstance.post(`/jobs/inquiry/`, data);
};

export const applySeekerCard = (data) => {
  return axiosInstance.post(`/seeker/apply/`, data);
};
export const getAppliedUserList = (id, pageC) => {
  return axiosInstance.get(`/seeker/applied_users/${id}/?page=${pageC}`);
};

export const getNewresouDetails = () => {
  return axiosInstance.get(`/seeker/newresodetails/`);
};

export const updateJobStatus = (id , data) => {
  return axiosInstance.put(`/seeker/applications/${id}/status/` , data);
}

export const getHrJobRoles = () => {
  return axiosInstance.get(`/seeker/hr-job-roles`);
}

export const createHrPlan = (data) => {
  return axiosInstance.post(`/seeker/hr-plans/`,data);
}

export const getHrPlanArray = (data) => {
  return axiosInstance.get(`/seeker/hr-plans?year=${data.year}&state=${data.state}&job_role_id=${data.job_role_id}`);
}

//edit Hr Plan
export const editHrPlanById = (id , data) => {
  return axiosInstance.put(`/seeker/hr-plans/${id}/` , data);
};

// delete HR Plan
export const deleteHrPlanById = (id) => {
  return axiosInstance.delete(`/seeker/hr-plans/${id}/`);
};


// get pre job assessment categories
export const getPreJobAssessmentCategoriesArray = () => {
  return axiosInstance.get(`/assessment/categories/`);
}

// assessment scheduled
export const getScheduledAssessmentsArray = () => {
  return axiosInstance.get(`/assessment/scheduled-assessments/`);
}

// assessment details
export const getAssessmentDetailsArray = (id) => {
  return axiosInstance.get(`/assessment/scheduled-assessments/${id}/`);
}

// get interview scheduled
export const getInterviewScheduledArray = (state , job) => {
  return axiosInstance.get(`assessment/scheduled-interviews`);
}

// get seeker card by state
export const getSeekerCardByState = (state) => {
  return axiosInstance.get(`/seeker/seeker-cards/?state=${state}`);
};

// schedule interview
export const scheduleInterview = (data) => {
  return axiosInstance.post(`/assessment/interview/create/` , data);
}

// get ITS by job id
export const getITS = (job_id) => {
  return axiosInstance.get(`/seeker/applied_users/${job_id}?status=ITS`);
}

// submit inquiry form
export const submitInquiry = (data) => {
  return axiosInstance.post(`/inquiry/create/`, data);
}

// get geoData
export const getGeographicalData = () =>{
  return axiosInstance.get(`provider/map-data/`);
}