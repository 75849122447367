import React, { useState } from "react";
import "./Navbar.min.css";
import logo from "../../asset/images/logo.png";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Signin from "../../pages/Auth/Sign-in/Signin";
import Signup from "../../pages/Auth/Sign-up/Signup";
import { getCookie, removeCookie } from "../../config/cookies";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { modalManage } from "../../redux/slices/authModalHandleSlice";
import { ClearAuthSign } from "../../redux/slices/authSlice";
import {
  clearAllproviderSearchCard,
  clearAllproviderSearchCardTabChange,
} from "../../redux/slices/providerCardSlice";
import {
  clearAllSearchCard,
  clearAllSearchCardAfterTabChange,
} from "../../redux/slices/seekerCardSlice";

const Navbar = () => {
  const dispatch = useDispatch();
  const [language, setLanguage] = useState('en');

  const handleLoginShow = () => {
    dispatch(modalManage("login"));
  };

  const [signupShow, setSignupShow] = useState(false);
  const handleSignupShow = () => setSignupShow(true);

  const token = getCookie("authToken");

  const userDetail = useSelector((state) => _.get(state, "reducer.auth", {}));
  const handleLogOut = () => {
    window.location.href = "/";
    removeCookie("authToken");
    removeCookie("isSeeker");
    removeCookie("isProvider");
    removeCookie("expiresIn");
    dispatch(ClearAuthSign());
  };
  const queryParameters = window.location.pathname;

  const clearCard = () => {
    dispatch(clearAllSearchCardAfterTabChange());
    dispatch(clearAllproviderSearchCardTabChange());
  };

  const clearRequireCard = () => {
    dispatch(clearAllSearchCardAfterTabChange());
  };

  const clearAvailableCard = () => {
    dispatch(clearAllproviderSearchCardTabChange());
  };

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light "
        id="ftco-navbar"
        style={{ position: "fixed", top: "0" }}
      >
        <div className="container-fluid px-md-4	">
          <Link to="/home" className="navbar-brand">
            <img src={logo} width="180px" alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#ftco-nav"
            aria-controls="ftco-nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="oi oi-menu"></span> Menu
          </button>
          <div className="collapse navbar-collapse" id="ftco-nav">
            <ul className="navbar-nav ml-auto">
              <li className={`nav-item ${queryParameters === "/home" ? "active" : ""}`}>
                <Link to="/home" className="nav-link" onClick={clearCard}>
                  Home
                </Link>
              </li>
              <li className={`nav-item ${queryParameters === "/resourceavailable" ? "active" : ""}`}>
                <Link to="/resourceavailable" className="nav-link" onClick={clearRequireCard}>
                  Resource Available
                </Link>
              </li>
              <li className={`nav-item ${queryParameters === "/resourcerequire" ? "active" : ""}`} >
                <Link to="/resourcerequire" className="nav-link" onClick={clearAvailableCard}>
                  Resource Require
                </Link>
              </li>
              <li className={`nav-item ${queryParameters === "/assesement" ? "active" : ""}`}>
                <Link to="/assesement" className="nav-link" onClick={clearCard}>
                  Assesement
                </Link>
              </li>
              <li className={`nav-item ${queryParameters === "/price" ? "active" : ""}`}>
                <Link to="/price" className="nav-link" onClick={clearCard}>
                  Pricing
                </Link>
              </li>
              {/* <li className={`nav-item ${queryParameters === "/partner" ? "active" : ""}`}>
                <Link to="/partner" className="nav-link" onClick={clearCard}>
                  Partner
                </Link>
              </li> */}
              <li className={`nav-item ${queryParameters === "/faq" ? "active" : ""}`}>
                <Link to="/faq" className="nav-link" onClick={clearCard}>
                  FAQs
                </Link>
              </li>
              {!token ? (
                <>
                  {" "}
                  <li className="nav-item cta mr-lg-1 mr-0 d-flex align-items-center">
                    <Nav.Link
                      className="btn btn-outline-primary logout-btn headerBtn"
                      eventKey="7"
                      as={Link}
                      onClick={handleLoginShow}
                    >
                      Login
                    </Nav.Link>
                  </li>
                  <li className="nav-item cta d-flex align-items-center">
                    <Nav.Link
                      className="btn btn-outline-primary signup-btn headerBtn ml-0 ml-lg-3"
                      eventKey="6"
                      as={Link}
                      onClick={handleSignupShow}
                    >
                      Sign Up
                    </Nav.Link>
                  </li>
                </>
              ) : (
                <>
                  <li
                    className={`nav-item ${queryParameters === "/provider" ? "active" : ""
                      }`}
                  >
                    <Link
                      to={
                        _.get(userDetail, "user.is_provider", false)
                          ? "/provider"
                          : "/seeker"
                      }
                      className="nav-link"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className="nav-item cta d-flex align-items-center">
                    <Nav.Link
                      className="btn btn-outline-primary logout-btn headerBtn ml-0 ml-lg-3"
                      eventKey="6"
                      as={Link}
                      onClick={handleLogOut}
                    >
                      Logout
                    </Nav.Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
      <Signin />
      <Signup
        handleSignupShow={handleSignupShow}
        signupShow={signupShow}
        setSignupShow={setSignupShow}
      />
    </>
  );
};

export default Navbar;
