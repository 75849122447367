// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBDkoDumiKT0-xIaG7UuYnSbRb3kMueR-4",
  authDomain: "resolab-app.firebaseapp.com",
  projectId: "resolab-app",
  storageBucket: "resolab-app.appspot.com",
  messagingSenderId: "721036138620",
  appId: "1:721036138620:web:90a3d9a74936be82b391f9",
  measurementId: "G-V6LQB2Y1BW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export default app;
