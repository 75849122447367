import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getAllResourceCategories } from '../../redux/slices/providerCardSlice';
import { getSignleProviderCards } from '../../redux/slices/providerCardSlice';
import { uploadProviderDocs, awsUpdateImage1, awsUpdateImage2 } from '../../services/providerService';
import { toast } from "react-toastify";
import { deleteEduDocs, deleteExpDocs } from '../../services/providerService';
import { Loader } from 'semantic-ui-react';
import Swal from 'sweetalert2';
import { Button } from '@material-tailwind/react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";


const UploadDocs = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const provoiderCardsArray = useSelector((state) =>
        _.get(state, "reducer.provoiderCard", {})
    );
    const [employmentDetails, setemploymentDetails] = useState([
        {
            id: "",
            organization_name: "",
            designation: "",
            experience: null,
            start_month: "",
            start_year: "",
            end_month: "",
            end_year: "",
            total_workduration: 0,
        },
    ]);
    const [eduValues, seteduValues] = useState([
        {
            id: "",
            course_name: "",
            degree_name: "",
            degree_college: "",
            passing_year: "",
            rescard: null,
        },
    ]);
    // const educationDocs
    const providerCardsArray = useSelector((state) =>
        _.get(state, "reducer.providerCard", {})
    );


    const handleUpload = async (event, id, val) => {
        setLoading(true);
        if (!event.target.files[0]) {
            setLoading(false);
            return;
        }
        const data1 = {
            file_name: event.target.files[0].name,
            media_type: val
        }
        const res = await awsUpdateImage1(data1).catch((err) =>
            toast.error(_.get(err, "message.message", "")),
            setLoading(false),
        );
        let formData = new FormData();
        for (var key in res.data.fields) {
            formData.append(key, res.data.fields[key]);
        }
        formData.append("file", event.target.files[0]);
        const res1 = await awsUpdateImage2(formData).catch((err) =>
            toast.error(_.get(err, "message.message", "")),
            setLoading(false),
        );
        if (res1.status === 204) {
            let data3 = {};
            if (val === "education") {
                data3["education"] = [{
                    id: id,
                    file_keys: res.data.fields.key,
                }]
            } else {
                data3["experience"] = [{
                    id: id,
                    file_keys: res.data.fields.key,
                }]
            }
            const response = await uploadProviderDocs(data3).catch((err) =>
                toast.error(_.get(err, "message.message", "")),
                setLoading(false),
            );
            if (_.get(response, "status", 0) === 1) {
                setLoading(false);
                toast.success(_.get(response, "message", ""));
                await dispatch(getSignleProviderCards("createCard"));
            }
        }
    }

    const handleDelete = async (id, val) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this document?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                let response = {};
                if (val == "education") {
                    response = await deleteEduDocs(id).catch((err) =>
                        toast.error(_.get(err, "message.message", ""))
                    );
                } else {
                    response = await deleteExpDocs(id).catch((err) =>
                        toast.error(_.get(err, "message.message", ""))
                    );
                }
                if (_.get(response, "status", 0) === 1) {
                    toast.success(_.get(response, "message", ""));
                    await dispatch(getSignleProviderCards("createCard"));
                }
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        dispatch(getAllResourceCategories());
        dispatch(getSignleProviderCards("createCard"));
    }, [dispatch]);
    console.log(providerCardsArray);
    return (
        <div className="p-10">
            {loading  && <Loader active inline='centered' />}
            {_.get(provoiderCardsArray, "isLoading", false) && <Loader />}
            <div>
                <h4 className="mb-4">Educational Background Documents</h4>
                <TableContainer component={Paper} className=" overflow-hidden bg-lightblue">
                    <Table className="table" sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell scope="col" className="font-bold col-4">Degree</TableCell>
                                <TableCell scope="col" className="font-bold col-4">Year</TableCell>
                                <TableCell scope="col" className="font-bold col-4">File Upload</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.get(provoiderCardsArray, "singleResoCard[0].rescards", []).map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell className="col-4">{item.course_name}, {item.degree_college}</TableCell>
                                    <TableCell className="col-4">{item.passing_year}</TableCell>
                                    <TableCell className="col-4">
                                        {item.documents && item.documents.map((docs, index) => (
                                            <div key={docs.id} className="my-1 flex lg:flex-row flex-col gap-[5px] justify-between">
                                                <a href={`https://docs.google.com/gview?url=${encodeURIComponent(`${docs.file}`)}`} target="_blank" rel="noopener noreferrer" className="no-underline text-center col-6 bg-gold">
                                                    <div className=" p-2 rounded-xl">
                                                        <span className="me-2">File - {index + 1}</span>
                                                        <i className="bi bi-file-text"></i>
                                                    </div>
                                                </a>
                                                <Button disabled={loading} className="bg-red" onClick={() => handleDelete(docs.id, "education")}>Delete</Button>
                                            </div>
                                        ))}
                                        <div className='my-3'>
                                            <input type='file' className='form-control' onChange={(event) => handleUpload(event, item.id, "education")} />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div>
                <h4 className="mb-4">Work Experience Documents</h4>
                <TableContainer component={Paper} className=" overflow-hidden bg-lightblue">
                    <Table className="table" sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell scope="col" className="col-4 font-bold">Designation</TableCell>
                                <TableCell scope="col" className="col-4 font-bold">Timeline</TableCell>
                                <TableCell scope="col" className="col-4 font-bold">File Upload</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.get(provoiderCardsArray, "singleResoCard[0].exps", []).map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell className="col-4">{item.designation} , {item.organization_name}</TableCell>
                                    <TableCell className="col-4">{item.start_month} {item.start_year} - {item.end_month} {item.end_year == 0 ? "" : item.end_year}</TableCell>
                                    <TableCell className="col-4">
                                        {item.documents && item.documents.map((docs, index) => (
                                            <div key={docs.id} className="my-1 flex lg:flex-row flex-col gap-[5px] justify-between">
                                                <a href={`https://docs.google.com/gview?url=${encodeURIComponent(`${docs.file}`)}`} target="_blank" rel="noopener noreferrer" className="no-underline text-center col-6 bg-gold">
                                                    <div className=" p-2 rounded-xl">
                                                        <span className="me-2">File - {index + 1}</span>
                                                        <i className="bi bi-file-text"></i>
                                                    </div>
                                                </a>
                                                <Button disabled={loading} className="bg-red" onClick={() => handleDelete(docs.id, "experience")}>Delete</Button>
                                            </div>
                                        ))}
                                        <div className='my-3'>
                                            <input type='file' className='form-control' onChange={(event) => handleUpload(event, item.id, "experience")} />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

        </div>
    );
};

export default UploadDocs;
