import { useState } from "react";
import Footer from "../../components/footer";
import NormalTop from "../../components/normalTop";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";


const Product = () => {

    const navigate = useNavigate();
    const openGmailCompose = () => {
        const receiverEmail = 'resolab@enliven-solutions.com';
        const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${receiverEmail}`;
        window.open(mailtoLink);
    };

    return (
        <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-center gap-[100px] leading-[normal] tracking-[normal] mq800:gap-[25px] mq1150:gap-[50px]">
            <NormalTop heading="Revolutionize with Our 4-in-1 Solution" />
            <section className="relative py-0 box-border w-full text-21xl  text-center flex flex-col gap-[40px] items-center justify-center">
                <div className="lg:w-3/4 w-[90%] relative bg-white h-auto overflow-hidden text-5xl text-black flex flex-col gap-[40px]" >
                    <div className="w-full rounded-11xl overflow-hidden bg-whitesmoke">
                        <h1 className="bg-darkslateblue-100 text-32xl font-dosis text-white py-6 mt-[0px]">HIRING MADE EASY</h1>
                        <div className="flex flex-col gap-[20px] mb-[20px] justify-center ">
                            <h1 className="font-raleway font-normal text-inherit px-5">Save Time by Interviewing Right People</h1>
                            <div>
                                <Button className="rounded-3xs  bg-forestgreen font-semibold items-center justify-center py-[15px] px-[50px] text-5xl text-white"
                                    sx={{
                                        textTransform: "none",
                                        "&:hover": { background: "#015498" },
                                    }}
                                    onClick={() => navigate('/resourceavailable')}
                                    >
                                    Get Started
                                </Button>
                            </div>
                            <h1 className="text-32xl font-dosis text-darkslateblue-100 px-5">
                                Use Our Services to Find Right Talent
                            </h1>
                            <div className=" flex flex-col lg:flex-row items-center justify-evenly">
                                <div className="flex flex-col items-center justify-center gap-[24px] lg:w-[33%] px-5">
                                    <img
                                        src="/fi-818149.svg"
                                    />
                                    <b className="text-9xl">
                                        <p className="m-0">Time Saving</p>

                                    </b>
                                    <div className=" text-5xl">
                                        <p className="m-0">Select Deserving Candidates</p>
                                    </div>
                                </div>
                                <div className="w-[175px] flex flex-col items-start bg-black opacity-[0.25] my-[20px] justify-start pt-[1px] px-0 pb-0 box-border lg:hidden" />
                                <div className="h-[175px] flex flex-col items-start justify-start pt-[25px] px-0 pb-0 box-border mq800:hidden">
                                    <div className="w-px flex-1 relative box-border opacity-[0.25] border-r-[1px] border-solid border-black" />
                                </div>
                                <div className="flex flex-col items-center justify-center gap-[24px] lg:w-[33%] px-5" >
                                    <img
                                        alt=""
                                        src="/fi-5876814.svg"
                                    />
                                    <b className="text-9xl">
                                        <p className="m-0">Find Perfect Culture Fit</p>
                                    </b>
                                    <div className="relative text-5xl inline-block">
                                        <p className="m-0">Based on Skills and Performance</p>
                                    </div>
                                </div>
                                <div className="w-[175px] flex flex-col items-start bg-black opacity-[0.25] my-[20px] justify-start pt-[1px] px-0 pb-0 box-border lg:hidden" />
                                <div className="h-[175px] flex flex-col items-start justify-start pt-[25px] px-0 pb-0 box-border mq800:hidden">
                                    <div className="w-px flex-1 relative box-border opacity-[0.25] border-r-[1px] border-solid border-black" />
                                </div>
                                <div className="flex flex-col items-center justify-center gap-[24px] lg:w-[33%] px-5" >
                                    <img
                                        alt=""
                                        src="/fi-3803374.svg"
                                    />
                                    <b className="text-9xl">
                                        <p className="m-0">Automated Hiring</p>
                                    </b>
                                    <div className=" text-5xl inline-block">
                                        <p className="m-0">Use our Special Automated Dashboards</p>
                                    </div>
                                </div>
                            </div>
                            <div className="py-5">
                                <Button className="rounded-3xs bg-forestgreen font-semibold items-center justify-center py-[15px] px-[50px] text-5xl text-white"
                                    sx={{
                                        textTransform: "none",
                                        "&:hover": { background: "#015498" },
                                    }}
                                    onClick={()=> navigate('/price')}
                                    >
                                    Subscribe Now
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="w-full rounded-11xl overflow-hidden bg-whitesmoke">
                        <h1 className="bg-darkslateblue-100 text-32xl font-dosis text-white py-6 mt-[0px]">ASSESSMENTS TO EVALUATE TALENT</h1>
                        <div className="flex flex-col gap-[20px] mb-[20px] justify-center items-center ">
                            <h1 className="font-raleway font-semibold text-9xl w-3/4">
                                We have prepared series of tests to evaluate best talent.
                            </h1>
                            <div className="py-5">
                                <Button className="rounded-3xs bg-forestgreen font-semibold items-center justify-center py-[15px] px-[50px] text-5xl text-white"
                                    sx={{
                                        textTransform: "none",
                                        "&:hover": { background: "#015498" },
                                    }}
                                    onClick={() => navigate('/assesement')}
                                    >
                                    Read More
                                </Button>
                            </div>
                            <h1 className="font-raleway font-normal text-3xl w-3/4">
                                Pre employment assesement is the key in acquisition of resources. We are providing
                                job based assesements for MFI. Our online assessment tools viz Aptitude Tests,
                                Domain Tests etc. can be used to measure critical traits and skills.
                            </h1>
                            <div className="py-5">
                                <Button className="rounded-3xs bg-forestgreen font-semibold items-center justify-center py-[15px] px-[50px] text-5xl text-white"
                                    sx={{
                                        textTransform: "none",
                                        "&:hover": { background: "#015498" },
                                    }}
                                    onClick={openGmailCompose}
                                    >
                                    Speak With Us
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="w-full rounded-11xl overflow-hidden bg-whitesmoke">
                        <h1 className="bg-darkslateblue-100 text-32xl font-dosis text-white py-6 mt-[0px]">VERIFICATION</h1>
                        <div className="flex flex-col gap-[20px] mb-[20px] justify-center ">
                            <div className="flex flex-col items-center justify-center">
                                <h1 className="font-raleway font-semibold text-inherit">All profiles are verified and trusted</h1>
                                <h1 className="font-raleway  font-normal text-3xl w-3/4">
                                    Background verification of an employee is always a challenging task for every organisation, for MFI
                                    verification of employee lead to better portfolio to reduce risk of fraud. We are providing verifications
                                    solutions viz Physical address, PAN card, AADHAAR card, Criminal record, Emplyment and Education.
                                </h1>
                            </div>
                            <h1 className="text-32xl font-dosis text-darkslateblue-100">
                                Three Step Verification Process
                            </h1>
                            <div className=" flex flex-col lg:flex-row items-center justify-evenly ">
                                <div className="flex flex-col items-center justify-center gap-[24px] lg:w-[33%] px-5">
                                    <img
                                        src="/fi-5679293.svg"
                                    />
                                    <b className="text-9xl">
                                        <p className="m-0">Document</p>
                                        <p className="m-0">Verification</p>
                                    </b>
                                    <div className=" text-5xl">
                                        <p className="m-0">Select deserving</p>
                                        <p className="m-0">candidates</p>
                                    </div>
                                </div>
                                <div className="w-[175px] flex flex-col items-start bg-black opacity-[0.25] my-[20px] justify-start pt-[1px] px-0 pb-0 box-border lg:hidden" />
                                <div className="h-[175px] flex flex-col items-start justify-start pt-[25px] px-0 pb-0 box-border mq800:hidden">
                                    <div className="w-px flex-1 relative box-border opacity-[0.25] border-r-[1px] border-solid border-black" />
                                </div>
                                <div className="flex flex-col items-center justify-center gap-[24px] lg:w-[33%] px-5">
                                    <img
                                        alt=""
                                        src="/fi-10896607.svg"
                                    />
                                    <b className="text-9xl">
                                        <p className="m-0">Interview</p>
                                        <p className="m-0"> Module</p>
                                    </b>
                                    <div className="relative text-5xl inline-block">
                                        <p className="m-0">Based on Skills</p>
                                        <p className="m-0">and Performance</p>
                                    </div>
                                </div>
                                <div className="w-[175px] flex flex-col items-start bg-black opacity-[0.25] my-[20px] justify-start pt-[1px] px-0 pb-0 box-border lg:hidden" />
                                <div className="h-[175px] flex flex-col items-start justify-start pt-[25px] px-0 pb-0 box-border mq800:hidden">
                                    <div className="w-px flex-1 relative box-border opacity-[0.25] border-r-[1px] border-solid border-black" />
                                </div>
                                <div className="flex flex-col items-center justify-center gap-[24px] lg:w-[33%] px-5">
                                    <img
                                        alt=""
                                        src="/fi-7718886.svg"
                                    />
                                    <b className="text-9xl">
                                        <p className="m-0">Background </p>
                                        <p className="m-0">Verification</p>
                                    </b>
                                    <div className=" text-5xl inline-block">
                                        <p className="m-0">Use our special</p>
                                        <p className="m-0">automated dashboards</p>
                                    </div>
                                </div>
                            </div>
                            <div className="py-5">
                                <Button className="rounded-3xs bg-forestgreen font-semibold items-center justify-center py-[15px] px-[50px] text-5xl text-white"
                                    sx={{
                                        textTransform: "none",
                                        "&:hover": { background: "#015498" },
                                    }}
                                    onClick={() => navigate('/resourceavailable')}
                                    >
                                    See Resources
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="w-full rounded-11xl overflow-hidden bg-whitesmoke">
                        <h1 className="bg-darkslateblue-100 text-32xl font-dosis text-white py-6 mt-[0px]">ADVISORY SERVICES</h1>
                        <div className="flex flex-col gap-[20px] mb-[20px] justify-center items-center ">
                            <h1 className="font-raleway  font-normal text-9xl w-3/4">
                                We are providing advisory services to the company working as a
                                Project Implementing Agency (PIA) under skill development program
                                supported by State Government of GOl. We are having expertise in
                                providing end to end services to PIAs.
                            </h1>
                        </div>
                    </div>
                </div>
            </section >
            <Footer
                image58="/image-581@2x.png"
                image60="/image-60@2x.png"
                image581="/image-58-1@2x.png"
                image601="/image-60@2x.png"
            />
        </div >
    );
};

export default Product;
