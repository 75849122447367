import { useState } from "react";
import Footer from "../../components/footer";
import NormalTop from "../../components/normalTop";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";


const Partner = () => {

    return (
        <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-center gap-[100px] leading-[normal] tracking-[normal] mq800:gap-[25px] mq1150:gap-[50px]">
            <NormalTop heading="Partnership Program" />
            <section className="relative py-0 box-border w-full text-21xl font-semibold text-darkslateblue-100 text-center inline-block ">
                <div className="w-full relative bg-white h-auto overflow-hidden text-5xl text-black font-dosis flex flex-col items-center justify-center">
                    <div className=" text-29xl w-3/4">
                        <div className=" flex flex-col items-center justify-start gap-[30px] bg-whitesmoke rounded-xl lg:p-10 p-5">
                            <b className="relative lg:text-35xl text-9xl">What is Partnership Program</b>
                            <div className=" relative lg:text-9xl text-3xl font-medium font-raleway text-center  inline-block lg:px-20">
                                Any person looking to set up their own enterprises in their home
                                district within India can become our partner.
                            </div>
                        </div>
                    </div>

                    <div className="inset-0 z-10 lg:w-3/4 w-[85%] pt-[50px] ">
                        <TableContainer component={Paper} className="rounded-11xl overflow-hidden">
                            <Table sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                <TableHead className=" text-white text-center ">
                                    <TableRow className="bg-darkslateblue-100">
                                        <TableCell className="font-dosis text-32xl py-6 text-white text-center" colSpan={2} >Eligibility</TableCell>
                                    </TableRow>
                                    <TableRow className="sm:hidden">
                                        <TableCell className="text-darkslateblue-100 text-19xl font-dosis">Criteria</TableCell>
                                        <TableCell className="text-darkslateblue-100 text-19xl font-dosis" sx={{ px: 6, border: '1px solid rgba(0, 0, 0, 0.12)' }}>Details</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    <TableRow >
                                        <TableCell className="font-raleway text-3xl px-6 sm:hidden">Eligibility</TableCell>
                                        <TableCell className="font-raleway text-3xl px-6" sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                            <div className="lg:hidden font-bold">Age</div>
                                            <ul>
                                                <li>More than 18 years</li>
                                            </ul>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="font-raleway text-3xl px-6 sm:hidden" sx={{ px: 6 }}>Educational Qualification</TableCell>
                                        <TableCell className="font-raleway text-3xl px-6" sx={{ px: 6, border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                            <div className="lg:hidden font-bold">Educational Qualification</div>
                                            <ul>
                                                <li>Anyone with or without any degree.</li>
                                            </ul>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="font-raleway text-3xl px-6 sm:hidden" sx={{ px: 6 }}>Domicile</TableCell>
                                        <TableCell className="font-raleway text-3xl px-6" sx={{ px: 6, border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                            <div className="lg:hidden font-bold">Domicile</div>
                                            <ul>
                                                <li>Permanent resident of the proposed district</li>
                                            </ul>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell className="font-raleway text-3xl px-6 sm:hidden" sx={{ px: 6 }}>Skills Required</TableCell>
                                        <TableCell className="font-raleway text-3xl px-6" sx={{ px: 6, border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                            <div className="lg:hidden font-bold">Skills Required</div>
                                            <ul>
                                                <li>Good Communication Skill</li>
                                                <li>Understanding of Micro Finance & Human Resource</li>
                                                <li>Proficiency in MS Office</li>
                                            </ul>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div className="py-[50px] mt-[-30px] w-full" style={{ backgroundImage: `url('/rectangle-15@2x.png')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                        <div className="inset-0 flex flex-col lg:flex-row items-center justify-center text-white">
                            <h1 className="lg:w-1/4 font-dosis text-heading ">Deliverables</h1>
                            <div className="lg:w-2/4 flex flex-col justify-start items-start text-3xl text-left font-raleway ">
                                <ul className="flex flex-col gap-[10px]">
                                    <li >
                                        Acquisitions of talent (resources) who are working in micro
                                        finance sector or willing to work in the sector to our portal.
                                    </li>
                                    <li>Background verification of resources as needed.</li>
                                    <li>Enrollment of candidates in our upskilling course (online & offline).</li>
                                    <li>Organize Job-fairs/meetings in the district.</li>
                                    <li>
                                        Build relationships with registered Micro Finance Institutions
                                        (MFIs) in the district.
                                    </li>
                                    <li>Other tasks as required for business.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="relative lg:w-3/4 w-[90%]">
                        <h1 className="lg:text-left text-center text-heading">Payout To Partners</h1>
                        <TableContainer component={Paper} className="rounded-11xl overflow-hidden">
                            <Table sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                <TableHead className=" text-white text-center ">
                                    <TableRow className="bg-darkslateblue-100">
                                        <TableCell className="font-dosis text-32xl py-6 text-white text-center" colSpan={3} >Paid Partners</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell className="text-darkslateblue-100 lg:text-19xl text-7xl font-dosis">Sr. No.</TableCell>
                                        <TableCell className="text-darkslateblue-100 lg:text-19xl text-7xl font-dosis" sx={{ px: 6, border: '1px solid rgba(0, 0, 0, 0.12)' }}>Deliverables</TableCell>
                                        <TableCell className="text-darkslateblue-100 lg:text-19xl text-7xl font-dosis" sx={{ px: 6, border: '1px solid rgba(0, 0, 0, 0.12)' }}>Payout (INR)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    <TableRow >
                                        <TableCell className="font-raleway text-3xl px-6 font-semibold">1</TableCell>
                                        <TableCell className="font-raleway text-3xl px-6 font-semibold" sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                            Create 50 Resocard within 30 days
                                        </TableCell>
                                        <TableCell className="font-raleway text-3xl px-6">5,000</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell className="font-raleway text-3xl px-6 font-semibold">2</TableCell>
                                        <TableCell className="font-raleway text-3xl px-6 font-semibold" sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                            Create 100 Resocard within 90 days
                                        </TableCell>
                                        <TableCell className="font-raleway text-3xl px-6">10,800</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell className="font-raleway text-3xl px-6 font-semibold">3</TableCell>
                                        <TableCell className="font-raleway text-3xl px-6 font-semibold" sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                            Eligible for petrol Allowance after creating 1000 Resocard
                                        </TableCell>
                                        <TableCell className="font-raleway text-3xl px-6">1,000 per month</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell className="font-raleway text-3xl px-6 font-semibold">4</TableCell>
                                        <TableCell className="font-raleway text-3xl px-6 font-semibold" sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                            Verified card for minimum 25 & multiple of 25
                                        </TableCell>
                                        <TableCell className="font-raleway text-3xl px-6">25 per candidate</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell className="font-raleway text-3xl px-6 font-semibold">5</TableCell>
                                        <TableCell className="font-raleway text-3xl px-6 font-semibold" sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                            background Verification
                                        </TableCell>
                                        <TableCell className="font-raleway text-3xl px-6">Upto 500 per candidate</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell colSpan={3} className="font-raleway text-xl lg:px-6">
                                            <b>Terms and Conditions*</b>
                                            <ul className="pl-5 pr-2">
                                                <li className="px-1">
                                                    One-time Fees for Paid-Partner: Rs. 25000 + GST (18%).
                                                </li>
                                                <li className="px-1">
                                                    Fees will be returned if the payout is less than Rs 25000 within 1000 days.
                                                </li >
                                                <li className="px-1">GST paid will not be returned.</li>
                                            </ul>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                </div>
            </section>
            <Footer
                image58="/image-581@2x.png"
                image60="/image-60@2x.png"
                image581="/image-58-1@2x.png"
                image601="/image-60@2x.png"
            />
        </div>
    );
};

export default Partner;
