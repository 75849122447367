// Switches between different environments
const configSwitcher = (environmentType) => {
  let configuration = [];

  switch (environmentType) {
    case "localhost":
      configuration = {
        /* Local Config */
        API_URL: "https://backend.resolabindia.com/",
        // IMG_API_URL: "https://backend.resolabindia.com",
        IMG_API_URL:"",
      };
      break;
    case "resolabindia.com":
        configuration = {
          /* Local Config */
          API_URL: "https://backend.resolabindia.com/",
          // IMG_API_URL: "https://backend.resolabindia.com",
          IMG_API_URL:"",
        };
        break;
    default:
      configuration = {
        /* Default Local Config */
        API_URL: "https://backend.resolabindia.com/",
        // IMG_API_URL: "https://backend.resolabindia.com",
        IMG_API_URL:"",
      };
  }

  return configuration;
};

// Just change the string to 'local', 'sandbox', 'staging' or 'prod' to switch between different environments.
export const config = configSwitcher(window.location.hostname);
