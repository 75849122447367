import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import "react-toastify/dist/ReactToastify.css";
import {
  getAppliedUserList,
  getNewresouDetails,
  getSeekerCard,
  getSeekerCardById,
  getSeekerCardByUserID,
  getSeekerCardCount,
  getSeekerCardWithSearch,
  seekerSearch,
  getHrJobRoles,
  createHrPlan,
  getHrPlanArray,
  getPreJobAssessmentCategoriesArray,
  getScheduledAssessmentsArray,
  getAssessmentDetailsArray,
  getInterviewScheduledArray,
  getSeekerCardByState,
  getITS,
  getGeographicalData,
} from "../../services/seekerService";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const initialState = {
  isLoading: false,
  isPostResoCard: false,
  isAppliedUser: false,
  seekerCards: {
    next: "",
    count: "",
    data: [],
  },
  seekerCardByUser: [],
  seekerCardByState: [],
  singleSeekerCard: [],
  cardDetails: {},
  homeSearchCard: {
    next: "",
    count: "",
    data: [],
  },
  seekCardCount: {},
  appliedUser: {},
  model: { forgotpass: true },
  pageCount: 1,
  isSearch: false,
  searchValue: {},
  appliedCard: [],
  hrJobRoles: [],
  hrPlansArray: [],
  preJobAssessmentArray: [],
  scheduledAssessmentsArray: [],
  assessmentDetailsArray: [],
  interviewDetailsArray: [],
  interviewScheduled: [],
  geoGraphicalData:[],
};

//slice
const seekerCardSlice = createSlice({
  name: "seekercard",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    seekerCardSuccess: (state, action) => {
      state.seekerCards.data = [
        ...state.seekerCards.data,
        ...action.payload.results,
      ];
      state.seekerCards.count = action.payload.count;
      state.seekerCards.next = action.payload.next;
    },
    seekerCardByUserSuccess: (state, action) => {
      state.seekerCardByUser = action.payload;
    },
    seekerCardByStateSuccess: (state, action) => {
      state.seekerCardByState = action.payload;
    },
    getInterviewScheduledSuccess: (state, action) => {
      state.interviewScheduled = action.payload;
    },
    singleSeekerCardSuccess: (state, action) => {
      state.singleSeekerCard = action.payload;
    },
    clearSingleSeekerCardSuccess: (state, action) => {
      state.singleSeekerCard = action.payload;
    },
    cardDetailSuccess: (state, action) => {
      state.cardDetails = action.payload;
    },
    homeSearchSuccess: (state, action) => {
      state.homeSearchCard.data = [
        ...state.homeSearchCard.data,
        ...action.payload.results,
      ];
      state.homeSearchCard.count = action.payload.count;
      state.homeSearchCard.next = action.payload.next;
    },
    removeHomeSearchSuccess: (state, action) => {
      state.homeSearchCard.data = [];
      state.homeSearchCard.count = "";
      state.homeSearchCard.next = "";
    },
    removeSeekerSearchSuccess: (state, action) => {
      state.seekerCards.data = [];
      state.seekerCards.count = "";
      state.seekerCards.next = "";
    },
    seekerCardCountSuccess: (state, action) => {
      state.seekCardCount = action.payload;
    },
    appliedUserSuccess: (state, action) => {
      state.appliedUser = action.payload;
    },
    startPostResoCard(state) {
      state.isPostResoCard = true;
    },
    stopPostResoCard(state) {
      state.isPostResoCard = false;
    },
    startAppliedUser(state) {
      state.isAppliedUser = true;
    },
    stopAppliedUser(state) {
      state.isAppliedUser = false;
    },
    pageCountSuccess(state, action) {
      // state.pageCount = action.payload;
    },
    searchSuccess(state, action) {
      state.isSearch = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    appliedCardList(state, action) {
      state.appliedCard = action.payload;
    },
    hrJobRolesSuccess(state, action) {
      state.hrJobRoles = action.payload;
    },
    hrPlansArraySuccess(state, action) {
      state.hrPlansArray = action.payload;
    },
    preJobAssessmentArraySuccess(state, action) {
      state.preJobAssessmentArray = action.payload;
    },
    scheduledAssessmentsArraySuccess(state, action) {
      state.scheduledAssessmentsArray = action.payload;
    },
    assessmentDetailsArraySuccess(state, action) {
      state.assessmentDetailsArray = action.payload;
    },
    interviewDetailsArraySuccess(state, action) {
      state.interviewDetailsArray = action.payload;
    },
    getGeographicalDataSuccess(state, action) {
      state.geoGraphicalData = action.payload.data;
    },
  },
});

// Reducer
export default seekerCardSlice.reducer;

//Action
const {
  seekerCardSuccess,
  seekerCardByUserSuccess,
  seekerCardByStateSuccess,
  singleSeekerCardSuccess,
  clearSingleSeekerCardSuccess,
  cardDetailSuccess,
  startLoading,
  stopLoading,
  homeSearchSuccess,
  seekerCardCountSuccess,
  appliedUserSuccess,
  startPostResoCard,
  stopPostResoCard,
  startAppliedUser,
  stopAppliedUser,
  pageCountSuccess,
  searchSuccess,
  removeHomeSearchSuccess,
  setSearchValue,
  removeSeekerSearchSuccess,
  appliedCardList,
  hrJobRolesSuccess,
  hrPlansArraySuccess,
  preJobAssessmentArraySuccess,
  scheduledAssessmentsArraySuccess,
  assessmentDetailsArraySuccess,
  interviewDetailsArraySuccess,
  getInterviewScheduledSuccess,
  getGeographicalDataSuccess,
} = seekerCardSlice.actions;

export const getAllSeekerCards =
  (jobRoles, stateVal, page) => async (dispatch) => {
    try {
      dispatch(startLoading());
      let response;
      if (jobRoles || stateVal) {
        response = await getSeekerCardWithSearch(jobRoles, stateVal, page);
      } else {
        response = await getSeekerCard(page);
      }
      if (response) {
        // if (jobRoles || stateVal) {
        //   dispatch(searchSuccess(true));
        //   dispatch(setSearchValue({ jobRoles, stateVal }));
        //   dispatch(homeSearchSuccess(response));
        //   // dispatch(pageCountSuccess(page));
        // } else {
        dispatch(seekerCardSuccess(response));
        // dispatch(pageCountSuccess(page));
        // }
      }
      dispatch(stopLoading());
    } catch (e) {
      if (e.code === 500) {
      }
    }
  };

export const clearAllSearchCard = () => async (dispatch) => {
  await dispatch(removeHomeSearchSuccess());
  await dispatch(removeSeekerSearchSuccess());
  await dispatch(searchSuccess(false));
};

export const clearAllSeekerCard = () => async (dispatch) => {
  await dispatch(removeSeekerSearchSuccess());
};

export const clearAllSearchCardAfterTabChange = () => async (dispatch) => {
  await dispatch(removeHomeSearchSuccess());
  await dispatch(removeSeekerSearchSuccess());
  await dispatch(searchSuccess(false));
  await dispatch(setSearchValue({}));
};

export const getSeekerCardSearch =
  (jobRoles, jobLocation) => async (dispatch) => {
    try {
      let response = await seekerSearch(jobRoles, jobLocation);
      if (response) {
        dispatch(seekerCardSuccess(response));
      }
    } catch (e) {
      if (e.code === 500) {
      }
    }
  };

export const getUserSeekerCards = (currentPage) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let response = await getSeekerCardByUserID(currentPage);
    if (response) {
      dispatch(seekerCardByUserSuccess(response));
      // dispatch(stopPostResoCard())
    }
    dispatch(stopLoading());
  } catch (e) {
    if (e.code === 500) {
    }
  }
};

export const getSignleSeekerCard = (id) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let response = await getSeekerCardById(id);
    if (response) {
      dispatch(singleSeekerCardSuccess(_.get(response, "data", {})));
      // dispatch(startPostResoCard())
    }
    dispatch(stopLoading());
  } catch (e) {
    if (e.code === 500) {
    }
  }
};

export const getStartPostResoCard = () => async (dispatch) => {
  dispatch(startPostResoCard());
};
export const getStopPostResoCard = () => async (dispatch) => {
  dispatch(stopPostResoCard());
};
export const getStartAppliedUser = () => async (dispatch) => {
  dispatch(startAppliedUser());
};
export const getStopAppliedUser = () => async (dispatch) => {
  dispatch(stopAppliedUser());
};

export const clearSignleSeekerCard = (id) => async (dispatch) => {
  try {
    dispatch(clearSingleSeekerCardSuccess({}));
  } catch (e) {
    if (e.code === 500) {
    }
  }
};

export const getSignleSeekerCardDetails = (id) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let response = await getSeekerCardById(id);
    if (response) {
      dispatch(cardDetailSuccess(_.get(response, "data", {})));
    }
    dispatch(stopLoading());
  } catch (e) {
    if (e.code === 500) {
    }
  }
};

export const getHomeSeekerCardSearch =
  (jobRoles, stateId) => async (dispatch) => {
    try {
      dispatch(startLoading());
      let response = await seekerSearch(jobRoles, stateId);
      if (response) {
        dispatch(homeSearchSuccess(response));
      }
      dispatch(stopLoading());
    } catch (e) {
      if (e.code === 500) {
      }
    }
  };

export const getHomeSeekerCardCount = () => async (dispatch) => {
  try {
    let response = await getSeekerCardCount();
    if (response) {
      dispatch(seekerCardCountSuccess(response));
    }
  } catch (e) {
    if (e.code === 500) {
    }
  }
};

export const getAppliedUser = (id, pageC) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let response = await getAppliedUserList(id, pageC);
    if (response) {
      dispatch(appliedUserSuccess(response));
    }
    dispatch(stopLoading());
  } catch (e) {
    if (e.code === 500) {
    }
  }
};

export const addAppliedCards = (id, pageC) => async (dispatch) => {
  try {
    await dispatch(appliedCardList());
  } catch (e) { }
};

export const getLoginUserSeekerCardValueforEdit = (id) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let response = await getNewresouDetails(id);
    if (response) {
      dispatch(singleSeekerCardSuccess(response));
      // dispatch(startPostResoCard())
    }
    dispatch(stopLoading());
  } catch (e) {
    if (e.code === 500) {
    }
  }
};

export const getHrJobRolesArray = () => async (dispatch) => {
  try {
    let response = await getHrJobRoles();
    if (response) {
      dispatch(hrJobRolesSuccess(response));
    }
  } catch (e) {
    if (e.code === 500) {
    }
  }
}

export const submitHrPlanningForm = (data) => async (dispatch) => {
  try {
    let response = await createHrPlan(data).catch((e) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Kindly fill all the required fields..!",  
      });
    });
    if (response) {
      toast.success(
        _.get(response, "message", "Profile edited successfully..!")
      );
    }
  } catch (e) {
    if (e.code === 500) {
      // removeCookie("authToken");
    }
  }
}

export const getHrPlans = (data) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let response = await getHrPlanArray(data).catch((e) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Kindly fill all the required fields..!",
      });
    });
    if (response) {
      dispatch(hrPlansArraySuccess(response));
    }
    dispatch(stopLoading());
  } catch (e) {
    if (e.code === 500) {
    }
  }
}


// for pre job assessment categories
export const getPreJobAssessmentCategories = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    let response = await getPreJobAssessmentCategoriesArray();
    if (response) {
      dispatch(preJobAssessmentArraySuccess(response));
    }
    dispatch(stopLoading());
  } catch (e) {
    if (e.code === 500) {
    }
  }
}

//for fetching scheduled assessments
export const getScheduledAssessments = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    let response = await getScheduledAssessmentsArray();
    if (response) {
      dispatch(scheduledAssessmentsArraySuccess(response));
    }
    dispatch(stopLoading);
  } catch (e) {
    if (e.code === 500) {
    }
  }
}

// for getting assessments details
export const getAssessmentDetails = (id) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let response = await getAssessmentDetailsArray(id);
    if (response) {
      dispatch(assessmentDetailsArraySuccess(response));
    }
    dispatch(stopLoading);
  } catch (e) {
    if (e.code === 500) {
    }
  }
}

// for fetching schduled interviews
export const getScheduledInterviews = (state, job) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let response = await getInterviewScheduledArray(state, job);
    if (response) {
      dispatch(interviewDetailsArraySuccess(response));
    }
    dispatch(stopLoading);
  } catch (e) {
    if (e.code === 500) {
    }
  }
}

export const getUserSeekerCardsByState = (state) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let response = await getSeekerCardByState(state);
    if (response) {
      dispatch(seekerCardByStateSuccess(response));
      // dispatch(stopPostResoCard())
    }
    dispatch(stopLoading());
  } catch (e) {
    if (e.code === 500) {
    }
  }
};

export const getInterviewToScheduledByJobId = (id) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let response = await getITS(id);
    if (response) {
      dispatch(getInterviewScheduledSuccess(response));
    }
    dispatch(stopLoading());
  } catch (e) {
    if (e.code === 500) {
    }
  }
};

export const getMapData = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    let response = await getGeographicalData();
    if (response) {
      dispatch(getGeographicalDataSuccess(response));
    }
    dispatch(stopLoading());
  } catch (e) {
    if (e.code === 500) {
    }
  }
}
