import { useState } from "react";
import Footer from "../../components/footer";
import NormalTop from "../../components/normalTop";
import { Button } from "@mui/material";

const Career = () => {

    const openGmailCompose = () => {
        const receiverEmail = 'resolab@enliven-solutions.com';
        const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${receiverEmail}`;
        window.open(mailtoLink);
    };

    return (
        <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-center gap-[100px] leading-[normal] tracking-[normal] mq800:gap-[25px] mq1150:gap-[50px]">
            <NormalTop heading="CAREERS" />
            <section className="relative py-0 box-border lg:w-[80%] w-[95%] text-21xl font-semibold text-darkslateblue-100 text-center inline-block ">
                <div className="flex flex-col items-center justify-center " >
                    <img
                        className=" rounded-11xl max-w-[1100px] w-full object-cover "
                        alt=""
                        src="/rectangle-9@2x.png"
                    />
                    <div className=" lg:mt-[-100px] mt-[-50px] lg:w-[70%] w-[90%] flex flex-col items-center justify-center py-10 px-10  gap-[30px] bg-whitesmoke rounded-11xl z-[1]">
                        <div className="text-19xl font-semibold font-dosis text-black text-center inline-block transform ">
                            We welcome talent from all across the country. We are committed to
                            fostering a work environment that is inclusive, diverse, in which all of
                            our team members can thrive and are treated with equal respect and
                            dignity.
                        </div>
                        <Button className="rounded-3xs bg-forestgreen font-semibold items-center justify-center py-[15px] px-[50px] text-5xl text-white"
                            onClick={openGmailCompose}
                            sx={{
                                textTransform: "none",
                                color: "#fff",
                                fontSize: "22",
                                background: "#015498",
                                borderRadius: "10px",
                                "&:hover": { background: "#015498" },
                            }}>
                            CONNECT US
                        </Button>
                    </div>
                </div>
            </section>
            <Footer
                image58="/image-581@2x.png"
                image60="/image-60@2x.png"
                image581="/image-58-1@2x.png"
                image601="/image-60@2x.png"
            />
        </div>
    );
};

export default Career;
