import React from "react";
import "./PageLoader.min.css";
// import { Audio } from "react-loader-spinner";
import { Triangle } from "react-loader-spinner";

const PageLoader = () => {
  return (
    <section
      class="ftco-section services-section assesement-service provider-detail-section"
      style={{ height: "100vh",display: "flex",
            justifyContent: "center",
            alignItems:'center' }}
    >
      <div class="container" style={{
            display: "flex",
            justifyContent: "center",
            alignItems:'center'
          }}>
        <div
        >
          {/* <Audio
            height="200"
            width="400"
            radius="9"
            color="green"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          /> */}
          <Triangle
          height="200"
          width="400"
          color="#4fa94d"
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
        </div>
      </div>
    </section>
  );
};

export default PageLoader;
